.pricing-table-container {
    display: flex;
    position: absolute;
    left: 0%;
    top: 50px;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 50px);
    width: 100%;
    background-color: var(--pale-green);
    border: 2px solid var(--light-green);
    overflow: hidden;
  
  }
  
  stripe-pricing-table {
    position: absolute;
    width: 100%;
  }

  .app-main .back-button {
    align-self: flex-start;
  }
 
  