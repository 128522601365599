/* Importing fonts from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Montserrat:wght@400;500;600;700&display=swap');

/* Root variables for colors */
:root {
  --dark-green: #1e4620;
  --medium-green: #2e6930;
  --light-green: #4a8f4d;
  --pale-green: #c5e1c6;
  --off-white: #f8f9fa;
  --pure-white: #ffffff;
}

/* Universal styles */
* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', Arial, sans-serif;
}

/* Global styles */
body {
  font-size: 16px;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--dark-green);
  background-color: var(--off-white);
}

/* Heading styles */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5em;
}

/* Heading font sizes */
h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

/* Paragraph spacing */
p {
  margin-bottom: 1rem;
}