/* Navbar styling */
.navbar {
  background-color: var(--dark-green);
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Brand section with logos */
.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-logo {
  display: none; /* Hide the icon logo */
}

.navbar-text-logo {
  height: 30px;
  width: auto;
}

/* Desktop Navigation */
.navbar-nav {
  display: flex;
  align-items: center;
}

.nav-list {
  display: flex;
  gap: 20px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-item .nav-link {
  color: var(--off-white);
  text-decoration: none;
  font-size: 1rem;
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.nav-item .nav-link span {
  font-size: 1.5rem;
}

.nav-item .nav-link:hover {
  color: var(--pale-green);
  background-color: rgba(255, 255, 255, 0.2);
}

/* Logout link */
.nav-item .logout-link:hover {
  color: var(--pure-white);
  background-color: #ff4757;
}

/* Mobile Navigation */
.mobile-nav {
  display: none;
  position: relative;
}

.menu-button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.menu-button img {
  height: 24px;
  width: auto;
  filter: brightness(0) invert(1); /* Makes the menu icon white */
}

.mobile-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--dark-green);
  padding: 1rem;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 200px;
  z-index: 1000;
  animation: slideIn 0.3s ease-out;
  transform-origin: top right;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .mobile-nav {
    display: block;
  }

  .mobile-menu .nav-list {
    flex-direction: column;
    gap: 10px;
  }

  .navbar-text-logo {
    height: 25px;
  }

  .mobile-menu .nav-item .nav-link {
    width: 100%;
    box-sizing: border-box;
    color: white;
    font-weight: 500;
  }

  .mobile-menu .nav-item .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
}
