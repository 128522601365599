/* Fullscreen overlay for popup */
.create-note-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Popup content styling */
.popup-content {
  position: relative;
  padding: 30px;
  margin: 0 10px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: var(--off-white);
}

/* Popup heading */
.popup-content h2 {
  margin-bottom: 20px;
  color: var(--dark-green);
}

/* Close button container */
.close-button-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Close button */
.close-btn {
  border: none;
  cursor: pointer;
  background: none;
  font-size: 1.5rem;
  color: var(--dark-green);
  padding: 5px;
  line-height: 1;
}

.close-btn:hover {
  color: var(--medium-green);
}

/* Start recording button */
.start-recording-btn {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--off-white);
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  width: 100%;
  cursor: pointer;
  margin-bottom: 20px;
  background-color: var(--medium-green);
  transition: background-color 0.3s ease;
}

.start-recording-btn:hover {
  background-color: var(--dark-green);
}

.start-recording-btn img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/* Dropdown for selecting language */
.language-select {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid var(--light-green);
}

.settings-options {
  margin-bottom: 20px;
}

.checkbox-grid {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 5px;
}

.recording-container {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

/* Individual sound wave bars */
.sound-bar {
  width: 10px;
  height: 100%;
  margin: 0 5px;
  border-radius: 5px;
  transform: scaleY(0.2);
  background-color: var(--medium-green);
  animation: soundWave 2s ease-in-out infinite;
}

.sound-bar.paused {
  animation-play-state: paused;
}

.sound-bar.standby {
  transform: scaleY(0.2); 
  animation: none;
}

/* Animation for sound wave bars */
@keyframes soundWave {
  0%, 100% {
    transform: scaleY(0.2);
  }
  50% {
    transform: scaleY(0.5);
  }
}

.recording-controls {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.recording-controls button {
  color: var(--pure-white);
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: var(--medium-green);
  transition: background-color 0.3s ease;
}

.recording-controls button:hover {
  background-color: var(--dark-green);
}

.dictation-textarea {
  width: 100%;
  height: 300px;
  padding: 15px;
  font-size: 16px;
  line-height: 1.5;
  border: 2px solid var(--light-green);
  border-radius: 8px;
  background-color: var(--pure-white);
  color: var(--dark-green);
  resize: vertical;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.dictation-textarea:focus {
  outline: none;
  border-color: var(--medium-green);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/* New styles for Preparing Transcript state */
.preparing-transcript {
  text-align: center;
  padding: 20px;
}

.preparing-transcript h2 {
  margin-bottom: 20px;
  color: var(--dark-green);
}

.loading-spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: var(--medium-green);
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}