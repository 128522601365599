.credit-popup-container {
  position: relative;
  background-color: var(--off-white);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 90%;
  z-index: 1001; /* Ensure it's above the wrapper */
}

.credit-popup-title {
  color: var(--dark-green);
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 600;
}

.credit-popup-message {
  color: var(--medium-green);
  margin-bottom: 20px;
  font-size: 1rem;
}

.credit-popup-button {
  color: var(--off-white);
  border: none;
  border-radius: 25px;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: linear-gradient(45deg, var(--medium-green), var(--light-green));
}

.credit-popup-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background: linear-gradient(45deg, var(--light-green), var(--medium-green));
}

.credit-popup-button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: linear-gradient(45deg, var(--dark-green), var(--medium-green));
}

.credit-popup-button::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  z-index: -1;
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(45deg);
  transition: all 0.3s;
}

.credit-popup-button:hover::before {
  left: -100%;
  top: -100%;
}

.padlock-image {
  width: 60%;
  height: auto;
  margin-bottom: 10px;
}

/* We've removed the .credit-popup-wrapper styles from here as they are now inline in EditPanel.jsx */

@media (max-width: 768px) {
  .credit-popup-container {
    width: 95%;
    padding: 15px;
  }

  .credit-popup-title {
    font-size: 1.3rem;
  }

  .credit-popup-message {
    font-size: 0.9rem;
  }

  .credit-popup-button {
    width: 100%;
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}
